import React from 'react'
import './Reviews.css'

function Reviews() {
  return (
    <>
        <div className='review-box'>
        <h1 className='reviewsheading' >Reviews</h1>
        <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/25361625' frameborder='2' width='100%' height='500'></iframe>                  
         </div>
      
</>
  )
}

export default Reviews